import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'membershipDuration',
    'allotmentAmountMembershipGift',
    'totalAmount'
  ];

  connect() {
    this.membershipDurationTarget.addEventListener('change', () => this.updateTotalAmount());
    this.allotmentAmountMembershipGiftTarget.addEventListener('change', () => this.updateTotalAmount());
  }

  updateTotalAmount() {
    const params = new URLSearchParams({
      'gift[membership_duration]': this.membershipDurationTarget.value,
      'gift[allotment_amount_membership_gift]': this.allotmentAmountMembershipGiftTarget.value,
    });
    fetch(`/membership_gifts/new.json?${params.toString()}`).then((response) => {
      response.json().then((body) => {
        this.totalAmountTarget.innerHTML = body.formatted_total_amount;
      });
    });
  }
}
