import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'input',
  ];

  uncheckAll(checkedNode) {
    // Unchecks all the inputs that are not the currently clicked input
    this.inputTargets.forEach(inputNode => {
      if (!inputNode.isEqualNode(checkedNode)) {
        inputNode.checked = false;
        inputNode.disabled = false;

        // Force trigger change event
        inputNode.dispatchEvent(new Event('change'));
      }
    })
  }

  toggle(event) {
    const { target } = event;

    if (target && target.checked) {
      this.uncheckAll(target);
      target.setAttribute('disabled', 'disabled')
    }
  }
}
