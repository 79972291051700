import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'membershipAmount',
    'additionalAllotmentAmount',
    'promoCode',
    'paymentMethod',
    'transactionId'
  ];

  static values = {
    minimumAllotmentAmount: String,

    // We don't store the additionalAllotmentAmount on a membership, so in case of absence of the
    // `additionalAllotmentAmount` we should pass the `allotmentAmount` to the controller.
    // In that case that value will be used instead of the minimum + additional allotment amount
    allotmentAmount: String,
  };

  initialize() {
    this.membershipAmount = parseInt(this.membershipAmountTarget.value);
  }

  addDataLayer(event_name, amount = false, payment_type = false, transaction_id = false) {

    const first_item = {
      item_id: "lidmaatschap",
      item_name: "lidmaatschap",
      item_brand: "Lid worden",
      item_list_id: "lid_worden",
      item_list_name: "Lid worden",
    };

    const second_item = {
      item_id: "grond kopen",
      item_name: "grond kopen",
      item_brand: "Lid worden",
      item_list_id: "lid_worden",
      item_list_name: "Lid worden",
    };

    const dataLayer = {
      event: event_name,
    };

    dataLayer["ecommerce"] = {
      affiliation: "mijn grond",
      items: [first_item, second_item],
    };

    if (payment_type) {
      // The disabled method is the selected payment method
      this.paymentMethodTargets.forEach((paymentMethod) => {
        if (paymentMethod.getAttribute("disabled") == "disabled") {
          dataLayer["ecommerce"]["payment_type"] = paymentMethod.getAttribute(
            "data-payment-description"
          );
        }
      });
    }

    if (transaction_id) {
      dataLayer["ecommerce"]["transaction_id"] = this.transactionIdTarget.value;
    }

    if (amount && (this.hasAdditionalAllotmentAmountTarget || this.hasAllotmentAmountValue)) {
      const totalAllotmentAmount = this.getTotalAmount()

      first_item["price"] = this.membershipAmount;
      first_item["currency"] = "EUR";
      first_item["quantity"] = "1";
      second_item["price"] = totalAllotmentAmount;
      second_item["currency"] = "EUR";
      second_item["quantity"] = "1";

      dataLayer["ecommerce"]["value"] =
        this.membershipAmount + totalAllotmentAmount;
      dataLayer["ecommerce"]["currency"] = "EUR";
      dataLayer["ecommerce"]["coupon"] = this.promoCodeTarget.value;
    }

    window.dataLayer.push(dataLayer);
  }

  // Either return the total amount if that's present.
  // Otherwise calculcate total amount based on additional + minimum
  // allotment amount
  getTotalAmount() {
    if (this.allotmentAmountValue) {
      return parseFloat(this.allotmentAmountValue);
    }

    const additionalAllotmentAmount = parseFloat(
      this.additionalAllotmentAmountTarget.value
    ).toFixed(2);
    const minimumAllotmentAmount = parseFloat(
      this.minimumAllotmentAmountValue
    ).toFixed(2);

    return parseFloat(additionalAllotmentAmount) + parseFloat(minimumAllotmentAmount);
  }

  addDataLayerViewItem() {
    this.addDataLayer("view_item");
  }

  addDataLayerAddToCart() {
    this.addDataLayer("add_to_cart", true);
  }

  addDataLayerBeginCheckout() {
    this.addDataLayer("begin_checkout", true);
  }

  addDatalayerPaymentInfo() {
    this.addDataLayer("add_payment_info", true, true);
  }

  addDataLayerPurchase() {
    this.addDataLayer("purchase", true, true, true);
  }
}
