import { Controller } from "stimulus"

// A controller that can toggle the disabled state of an unlockable target
export default class extends Controller {
  static targets = [ "unlockable" ]

  toggle({target}) {
    if (!target && !this.unlockableTarget) return;

    this.unlockableTarget.disabled = !target.checked;
  }
}
