import { Controller } from 'stimulus';

// Submit Controller
// ===
// Define fields that need to be watched for change.
// Every change triggers a form submit.

export default class extends Controller {
  submit() {
    this.element.submit();
  }
}
