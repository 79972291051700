import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'recurringMessage',
    'totalPrice',
    'totalPriceOverview',
    'recurringEnabled',
    'recurringFrequency',
    'frequencyText'
  ];

  connect() {
    this.calculate();
  }

  calculate() {
    var toggle = document.getElementById('confirmToggle');

    if (
      this.totalPriceTarget.value >= 20
    ) {
      toggle.style.cursor = 'auto';
      toggle.removeAttribute('disabled');
    } else {
      toggle.setAttribute('disabled', 'disabled');
      toggle.style.cursor = 'not-allowed';
    }

    if (this.recurringEnabledTarget.checked) {
      this.recurringMessageTarget.style.display = 'inline';
      this.frequencyTextTarget.innerHTML = this.recurringFrequencyTarget.options[this.recurringFrequencyTarget.selectedIndex].text.toLowerCase();
    } else {
      this.recurringMessageTarget.style.display = 'none';
      this.frequencyTextTarget.innerHTML = '';
    }

    this.totalPriceOverviewTargets.forEach(el => {
      el.innerHTML = this.totalPriceTarget.value;
    });
  }
}
