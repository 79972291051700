import { Controller } from "stimulus";

// A controller that grabs the controller element and gives that a CSS variable
// containing it's offset to the top of the document
export default class extends Controller {
  initialize() {
    const { element } = this;

    if (element) {
      const elementRect = element.getBoundingClientRect();
      const offsetTop = elementRect.top;

      // If for some reason we can't find the `offsetTop` do an early return
      if (!offsetTop) return;
      element.style.setProperty("--offset-top", `${offsetTop}px`);
    }
  }
}
