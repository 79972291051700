import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'acceptConditions',
    'commission',
    'commissionOverview',
    'recurringMessage',
    'squareMeterPrice',
    'squareMeters',
    'squareMetersOverview',
    'totalPrice',
    'totalPriceOverview',
    'recurringEnabled',
    'recurringFrequency',
    'frequencyText'
  ];

  connect() {
    this.calculate();
  }

  calculate() {
    var toggle = document.getElementById('confirmToggle');

    if (
      this.totalPriceTarget.value >= 20 &&
      this.acceptConditionsTarget.checked
    ) {
      toggle.style.cursor = 'auto';
      toggle.removeAttribute('disabled');
    } else {
      toggle.setAttribute('disabled', 'disabled');
      toggle.style.cursor = 'not-allowed';
    }

    if (this.recurringEnabledTarget.checked) {
      this.recurringMessageTarget.style.display = 'inline';
      this.frequencyTextTarget.innerHTML = this.recurringFrequencyTarget.options[this.recurringFrequencyTarget.selectedIndex].text.toLowerCase();
    } else {
      this.recurringMessageTarget.style.display = 'none';
      this.frequencyTextTarget.innerHTML = '';
    }

    var squareMeters = (
      (this.totalPriceTarget.value * (1 - this.getCommission())) /
      this.getPrice()
    ).toFixed(2);

    this.squareMetersTargets.forEach(el => {
      el.innerHTML = squareMeters;
    });

    this.totalPriceOverviewTargets.forEach(el => {
      el.innerHTML = this.totalPriceTarget.value;
    });

    this.commissionOverviewTarget.innerHTML = (
      this.totalPriceTarget.value * this.getCommission()
    ).toFixed(2);

    this.squareMetersOverviewTarget.innerHTML = (
      this.totalPriceTarget.value *
      (1 - this.getCommission())
    ).toFixed(2);
  }

  getPrice() {
    return this.squareMeterPriceTarget.getAttribute('data-square-meter-price');
  }

  getCommission() {
    return this.commissionTarget.getAttribute('data-commission');
  }
}
