import { Controller } from 'stimulus';

const options = {
  openClass: 'is-open'
}

export default class extends Controller {
  static targets = [
    'modal',
  ];

  initialize() {
    this.closeOnEscapePress();
  }

  open() {
    this.modalTarget.classList.add(options.openClass);
  }

  close() {
    this.modalTarget.classList.remove(options.openClass);
  }

  closeOnEscapePress() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    });
  }
}
